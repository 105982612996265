@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Bold.woff);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Bold.woff2);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Extrabold.woff2);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Medium.woff);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Medium.woff2);
}

/* @font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Regular.woff);
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Regular.woff2);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Semibold.woff);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Semibold.woff2);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Thin.woff);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Thin.woff2);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-UltraLight.woff);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-UltraLight.woff2);
} */

/* @font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Extrabold.woff);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Extrabold.woff2);
} */
/* @font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Heavy.woff);
}
@font-face {
  font-family: "Gilroy";
  src: url(./fonts/gilroy/Gilroy-Heavy.woff2);
} */

body {
  font-family: "Gilroy";
}
