.WrapperBack {
  background: #1f262d;
  color: white;
  padding: 2% 0 0;
}
.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.Wrapper a {
  text-decoration: none;
  color: #cccccc;
  transition: 0.65s;
  cursor: pointer;
  font-weight: medium;
  letter-spacing: 0.01em;
  margin-top: 10px !important;
}
.Wrapper ul:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Wrapper a:hover {
  color: #fcd318;
}
.Wrapper .icons {
  display: flex;
  align-items: center;
}
.icons p {
  margin: 0;
  font-size: 30px;
  margin-right: 5%;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1% 0;
  border-top: 1px solid white;
  margin-top: 3%;
}
.tel p {
  display: flex;
  flex-direction: column !important;
}
@media screen and (max-width: 730px) {
  .Wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    padding: 5%;
  }
  .Wrapper ul {
    margin-top: 5%;
  }
  .Wrapper img {
    margin: 2% 0 5%;
  }
  .bottom p {
    margin: 2% 0 0 0;
    padding: 0px 10px;
  }
}
