.MegaImg img {
  width: 100% !important;
  height: 100% !important;
}
.morrr .slick-slide {
  padding: 0;
}
.morrr .slick-next {
  background-color: #fcd318 !important;
  border-radius: 50%;
  color: black !important;
  width: 40px;
  height: 40px;
}
.morrr .slick-prev {
  background-color: #fcd318 !important;
  border-radius: 50%;
  color: black !important;
  width: 40px;
  height: 40px;
}
.morrr .slick-next {
  z-index: 1000 !important;
}
.morrr .slick-prev {
  z-index: 1000;
}
.morrr .slick-next:before,
.morrr .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  color: black;
}

.morrr .slick-prev::before {
  font-family: FontAwesome;
  content: "\f060";
  font-weight: 300;
}

.morrr .slick-next::before {
  font-family: FontAwesome;
  content: "\f061";
  font-weight: 300;
}
.morrr .slide {
  width: 100% !important;
}
.morrr .slide img {
  width: 100% !important;
}

@media screen and (max-width: 800px) {
  .morrr .slick-prev {
    display: none !important;
  }
  .morrr .slick-next {
    display: none !important;
  }
  .morrr .slick-prev::before {
    display: none !important;
  }
  .MegaImg {
    display: flex !important;
    justify-content: center !important;
  }
  .MegaImg img {
    width: 100% !important;
    height: 100% !important;
  }
}
