.Slide slick-next,
.Slide .slick-prev {
  background-color: transparent !important;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  width: 60px;
  height: 60px;
}
.Slide .slick-next {
  left: 13% !important;
  z-index: 1000 !important;
  display: none !important;
}
.Slide .slick-prev {
  position: absolute !important;
  left: 6% !important;
  z-index: 1000;
  display: none !important;
}
.Slide .slick-next:before,
.Slide .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  background: none;
}

.Slide .slick-prev::before {
  font-family: FontAwesome;
  content: "\f060";
  font-weight: 300;
}

.Slide .slick-next::before {
  font-family: FontAwesome;
  content: "\f061";
  font-weight: 300;
}
.Slide .slide {
  width: 100% !important;
}
.Slide .slide img {
  width: 100% !important;
}

@media screen and (max-width: 500px) {
  .Slide .slick-prev {
    display: none !important;
  }
  .Slide .slick-next {
    display: none !important;
  }
  .Slide .slick-prev::before {
    display: none !important;
  }
}
.slick-slide {
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  justify-content: center !important;
}
.Slide .slick-slide:nth-child(1) {
  background-image: url(../../../assets/hero/1.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px !important;
}

.Slide .slick-slide:nth-child(2) {
  background-image: url(../../../assets/hero/2.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px !important;
}
.Slide .slick-slide:nth-child(3) {
  background-image: url(../../../assets/hero/3.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px !important;
}

@media screen and (max-width: 500px) {
  .Slide .slick-slide:nth-child(1) {
    height: 350px !important;
  }
  .Slide .slick-slide:nth-child(2) {
    height: 350px !important;
  }
  .Slide .slick-slide:nth-child(3) {
    height: 350px !important;
  }
}

@media screen and (max-width: 420px) {
  .Slide .slick-slide:nth-child(1) {
    height: 400px !important;
  }
  .Slide .slick-slide:nth-child(2) {
    height: 400px !important;
  }
  .Slide .slick-slide:nth-child(3) {
    height: 400px !important;
  }
}