.WrapperHom .slick-next,
.WrapperHom .slick-prev {
  font-size: 0;
  line-height: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: transparent;
  border: none;
  background-color: transparent !important;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  outline: 0;
  z-index: 1000;
}
.WrapperHom .slick-next {
  left: 90% !important;
  top: 0;
}
.WrapperHom .slick-prev {
  left: 85% !important;
  top: 0;
}
.WrapperHom .slick-next:before,
.WrapperHom .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  color: #17365e;
  background: transparent !important;
}

.WrapperHom .slick-prev::before {
  font-family: FontAwesome;
  content: "\f060";
  font-weight: 300;
}

.WrapperHom .slick-next::before {
  font-family: FontAwesome;
  content: "\f061";
  font-weight: 300;
}

@media only screen and (max-width: 731px) {
  .WrapperHom .slick-prev {
    display: none !important;
  }

  .WrapperHom .slick-next {
    display: none !important;
  }
  .WrapperHom .slick-prev::before {
    display: none !important;
  }
}

.WrapperHom .WrapperHom {
  text-align: center;
  margin: 0 auto;
}

.WrapperHom .slick-active .card {
  transition: 0.2s ease-in-out;
  transform: scale(1.3) !important;
  box-shadow: 0px 0px 30px 0px #00000032;
}
.WrapperHom .slick-slide {
  transition: 0.2s ease-in-out;
  transform: scale(0.9) !important;
}

.WrapperHom .slick-center .asasas {
  transition: 0.4s;
}
.WrapperHom .sliderIte {
  padding: 20% 0% 20%;
}
.card {
  width: 99%;
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 30px;
  overflow: hidden;
}
.card .texts {
  padding: 3%;
}
.texts h2 {
  font-size: 30px;
  padding: 2% 0;
}

.card img {
  width: 100% !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 500px) {
  .WrapperHom .sliderIte {
    padding: 45% 0 20% !important;
  }
  .WrapperHom .slick-center .asasas {
    box-shadow: 0px 0px 30px 0px black !important;
  }
  .WrapperHom .sliderIte img {
    width: 80% !important;
    margin: 0 auto !important;
    text-align: center !important;
  }
  .texts h2 {
    font-size: 24px !important;
  }
  .card {
    width: 90% !important;
  }
}
@media screen and (max-width: 395px) {
  .WrapperHom .sliderIte {
    padding: 5% 0;
  }
  .WrapperHom .slick-center .asasas {
    box-shadow: 0px 0px 20px 0px black !important;
  }
}
.WrapperHom .card img {
  width: 500px;
  height: 300px;
  margin: 0 auto;
  border-radius: 30px;
}
