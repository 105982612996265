.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 0%;
}
.header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.header ul li a {
  text-decoration: none;
  color: black;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.lang {
  border: 1px solid #cccccc;
  padding: 12px 10px 9px;
  border-radius: 50%;
  cursor: pointer;
}

.menuIcon {
  display: none;
}

@media screen and (max-width: 1230px) {
  .header ul {
    width: 80%;
  }
}
@media screen and (max-width: 1100px) {
  .header ul {
    width: 85%;
  }
}
@media screen and (max-width: 1000px) {
  .header ul {
    display: none;
  }
  .menuIcon {
    display: block !important;
    font-size: 30px;
    cursor: pointer;
  }
  .header {
    padding: 10px 10px;
  }
}
@media screen and (max-width: 500px) {
  .header img {
    width: 90px;
    height: 100%;
  }
}
