.WrapperBack {
  padding: 5% 0;
  background-image: url(../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5%;
  position: relative;
}
.card:nth-child(2n) {
  display: flex;
  flex-direction: row-reverse;
}
.card:nth-child(2n) .vec {
  position: absolute;
  z-index: -1;
  left: 30%;
}
.vec {
  position: absolute;
  z-index: -1;
  right: 10%;
}
.Wrapper h2 {
  font-size: 40px;
  padding-bottom: 3%;
}
.card h3 {
  font-size: 30px;
}
.texts {
  width: 45%;
}
.card p {
  font-size: 20px;
  letter-spacing: 0.01em;
}
.card i {
  color: #2d5180;
  font-size: 35px;
}
.card ion-icon {
  color: #2d5180;
  font-size: 35px;
}
.card p {
  display: flex;
  align-items: center;
}
.card p span {
  margin-left: 2%;
}
.mainimg {
  width: 50% !important;
  border-radius: 20px;
}

@media screen and (max-width: 1005px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .card:nth-child(2n) {
    display: flex;
    flex-direction: column !important;
  }
  .texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .mainimg {
    width: 70% !important;
  }
  .vec {
    top: 55%;
    left: 65%;
  }
  .vecs {
    top: 0;
    left: 65%;
  }
}
@media screen and (max-width: 715px) {
  .vec {
    top: 45%;
  }
}

@media screen and (max-width: 500px) {
  .texts {
    padding: 0px 0px !important    ;
  }
  .texts h3 {
    padding: 0px 10px;
    font-size: 20px;
  }
  .texts p {
    margin-left: 3% !important;
  }
  .mainimg {
    width: 95% !important;
  }
}
@media screen and (max-width: 500px) {
  .Wrapper h2 {
    padding: 0 10px;
    font-size: 24px;
    padding-bottom: 7%;
  }
  .Wrapper h2 img {
    width: 130px;
  }
}
@media screen and (max-width: 390px) {
  .vec {
    top: 42%;
  }
  .vecs {
    top: 1%;
  }
}
