/* .WrapperBack {
  padding: 5% 0 3%;
  background-image: url(../../../assets/info/back.png);
  background-repeat: no-repeat;
  background-size: 850px;
  background-position-y: bottom;
  background-position-x: 118%;
}
.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.video {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
}
.header p {
  width: 40%;
  font-size: 16px;
  line-height: 160%;
}
.video iframe {
  width: 70%;
  height: 300px;
  border-radius: 30px;
}

@media screen and (max-width: 1180px) {
  .video iframe {
    width: 90%;
  }
}

@media screen and (max-width: 895px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
  }
  .header p {
    width: 100%;
  }
  .video {
    width: 100%;
  }
  .video iframe {
    width: 90%;
    height: 400px;
  }
  .WrapperBack {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: bottom;
    background-position-x: 100%;
  }
}

@media screen and (max-width: 665px) {
  .video iframe {
    width: 100%;
    height: 300px;
  }
} */

.WrapperBack {
  padding: 5% 0 20%;
  background-image: url(../../../assets/info/back.png),
    url(../../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: 720px, cover;
  background-position-x: right;
  background-position-y: 15%, bottom;
}
.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.why {
  width: 49%;
}
.video {
  width: 40%;
}
.subtext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  padding-bottom: 15%;
}
.iframe iframe {
  width: 100%;
  height: 300px;
  border-radius: 30px;
}
.whyDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35%;
}

.img {
  width: 450px;
  height: 510px;
  position: absolute;
  left: -5%;
}
.headImg {
  width: 140px;
  height: 140px;
  position: absolute;
  left: 0;
}
.sun {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  border-radius: 50px;
  background-color: white;
  margin-left: 18%;
  position: absolute;
  top: 38%;
}
.sun .sunTexts {
  display: flex;
  align-items: center;
  padding: 4% 4% 4% 6%;
}
.sun p {
  font-size: 16px;
  width: 40%;
}

.check {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  border-radius: 50px;
  background-color: white;
  margin-left: 90%;
  position: relative;
}
.check .checkTexts {
  display: flex;
  align-items: center;
  padding: 4% 4% 4% 4%;
}
.check p {
  font-size: 16px;
  width: 40%;
}

.hand {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  border-radius: 50px;
  background-color: white;
  margin-left: 90%;
  position: relative;
  margin-top: 15%;
}
.hand .handTexts {
  display: flex;
  align-items: center;
  padding: 4% 4% 4% 4%;
}
.hand p {
  font-size: 16px;
  width: 40%;
}

.medal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  border-radius: 50px;
  background-color: white;
  margin-left: 19%;
  bottom: -21%;
  position: absolute;
}
.medal .medalTexts {
  display: flex;
  align-items: center;
  padding: 4% 4% 4% 6%;
}
.medal p {
  font-size: 16px;
  width: 39% !important;
}
.forMedia {
  display: none;
}

.videoSubtext {
  margin-top: 15% !important;
}
.video p {
  line-height: 160%;
  font-size: 16px;
}
.video button {
  border: 1px solid #17365e;
  background-color: transparent;
  padding: 10px 20px;
  color: #17365e;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mediawhy {
  display: none;
}
.videoSubtextMedia {
  display: none;
}
.mediaButton {
  border: 1px solid #17365e;
  background-color: transparent;
  padding: 10px 20px;
  color: #17365e;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1295px) {
  .WrapperBack {
    background-size: 90%, cover;
    background-position-x: right;
    background-position-y: 20%, bottom;
  }
  .Wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
  }
  .sun,
  .check,
  .hand,
  .medal {
    position: none !important;
  }
  .why {
    display: none;
  }
  .mediawhy {
    display: block;
  }

  .mediasun {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    border-radius: 50px;
    background-color: white;
    position: relative;
  }
  .mediasun .mediasunTexts {
    display: flex;
    align-items: center;
    padding: 4% 4% 4% 7%;
  }
  .mediaheadImg {
    position: absolute;
    left: 0;
  }
  .mediahand p {
    font-size: 16px;
  }
  .mediahand {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    border-radius: 50px;
    background-color: white;
    position: relative;
    margin-bottom: 10%;
  }
  .mediahand .mediahandTexts {
    display: flex;
    align-items: center;
    padding: 4% 4% 4% 0%;
  }
  .mediahand p {
    font-size: 16px;
    width: 48%;
  }
  .mediacheck {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    border-radius: 50px;
    background-color: white;
    position: relative;
    margin: 10% 0;
  }
  .mediacheck .mediacheckTexts {
    display: flex;
    align-items: center;
    padding: 4% 4% 4% 4%;
  }
  .mediacheck p {
    font-size: 16px;
    width: 40%;
  }
  .mediamedal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    border-radius: 50px;
    background-color: white;
    position: relative;
  }
  .mediamedal .mediamedalTexts {
    display: flex;
    align-items: center;
    padding: 4% 4% 4% 3%;
  }
  .mediamedal p {
    font-size: 16px;
    width: 40%;
  }
  .forMedia {
    display: block;
  }
  .video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .videoSubtext {
    display: none;
  }
  .vsub {
    display: none;
  }
  .video button {
    display: none;
  }
  .videoSubtextMedia {
    display: block;
    margin-top: 5%;
  }
  .iframe {
    width: 60%;
  }
  .iframe iframe {
    width: 100%;
    height: 400px;
  }
  .mediawhy {
    margin: 5% 0;
  }
  .mediawhyDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 745px) {
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 20%, bottom;
  }
  .mediawhyDiv {
    display: flex;
    flex-direction: column;
  }
  .mediaimg {
    margin-bottom: 5%;
  }
  .iframe {
    width: 80%;
  }
}

@media screen and (max-width: 605px) {
  .iframe {
    width: 100%;
  }
  .iframe iframe {
    height: 300px;
  }
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 22%, bottom;
  }
}

@media screen and (max-width: 515px) {
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 23%, bottom;
  }
}

@media screen and (max-width: 465px) {
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 24%, bottom;
  }
}
@media screen and (max-width: 430px) {
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 25%, bottom;
  }
  .mediasun,
  .mediahand,
  .mediamedal,
  .mediacheck {
    width: 100%;
  }
  .mediacategories {
    width: 100%;
  }
  .mediawhyDiv {
    margin-top: 20%;
  }
  .mediaheadImg {
    width: 120px;
    height: 120px;
  }
  .mediacheck p {
    font-size: 16px;
    width: 42%;
  }
  .mediamedal p {
    font-size: 16px;
    width: 42%;
  }
  .mediahand p {
    font-size: 16px;
    width: 43%;
  }
  .mediasunTexts {
    padding: 4% 4% 4% 5% !important;
  }
}

@media screen and (max-width: 385px) {
  .WrapperBack {
    background-size: 100%, cover;
    background-position-x: right;
    background-position-y: 28%, bottom;
  }
  .mediaheadImg {
    width: 100px;
    height: 100px;
  }
  .mediacheckTexts img {
    height: 50px;
  }
  .mediasunTexts img {
    height: 50px;
  }
  .mediahandTexts img {
    height: 50px;
  }
  .mediamedalTexts img {
    height: 50px;
  }
  .mediacheck p {
    font-size: 16px;
    width: 45%;
  }
  .mediasun p {
    width: 40% !important;
  }
  .mediamedal p {
    font-size: 16px;
    width: 48%;
  }
  .mediahand p {
    font-size: 16px;
    width: 50%;
  }
  .mediasunTexts {
    padding: 4% 4% 4% 6% !important;
  }
  .videoSubtextMedia {
    margin-top: 13% !important;
  }
}
@media screen and (max-width: 500px) {
  .mediasun p {
    width: 40% !important;
  }
}

@media screen and (max-width: 340px) {
  .mediasunTexts {
    padding: 4% 4% 4% 6% !important;
  }
}
