.WrapperBack {
  background-color: #f6f6f7;
  padding: 5% 0 0;
}
.WrapperBack .h2 {
  font-size: 40px;
}
@media screen and (max-width: 500px) {
  .h2 {
    padding: 0 10px;
    font-size: 24px !important;
  }

}
