.Wrapper {
  padding: 10% 0;
  /* overflow: hidden; */
  color: white;
  margin: 0 auto !important;
  width: 100%;
}
.Wrapper1 {
  padding: 10% 0;
  overflow: hidden;
  color: white;
}

.Wrapper2 {
  padding: 10% 0;
  overflow: hidden;
  color: white;
}

.Slider img {
  width: 100% !important;
}

.Wrapper h2 {
  font-size: 50px;
  width: 70%;
  font-weight: bold;
}
.Wrapper h2 span {
  color: transparent;
}
.Wrapper p {
  font-size: 20px;
}
.Wrapper button {
  background-color: #fcd318;
  color: black;
  border: 1px solid #fcd318;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.3%;
}
.Wrapper button a {
  color: black;
  text-decoration: none;
}

.Wrapper1 h2 {
  font-size: 50px;
  width: 70%;
  font-weight: bold;
}
.Wrapper1 h2 span {
  color: transparent;
}
.Wrapper1 p {
  font-size: 20px;
}
.Wrapper1 button {
  background-color: #fcd318;
  color: black;
  border: 1px solid #fcd318;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.3%;
}
.Wrapper1 button a {
  color: black;
  text-decoration: none;
}

.Wrapper2 h2 {
  font-size: 50px;
  width: 70%;
  font-weight: bold;
}
.Wrapper2 h2 span {
  color: transparent;
}
.Wrapper2 p {
  font-size: 20px;
}
.Wrapper2 button {
  background-color: #fcd318;
  color: black;
  border: 1px solid #fcd318;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.3%;
}
.Wrapper2 button a {
  color: black;
  text-decoration: none;
}
@media screen and (max-width: 1040px) {
  .Wrapper {
    padding: 5% 0% !important;
  }
  .Wrapper h2 {
    font-size: 30px;
  }
  .Wrapper1 {
    padding: 5% 0% !important;
  }
  .Wrapper1 h2 {
    font-size: 30px;
  }
  .Wrapper2 {
    padding: 5% 0% !important;
  }
  .Wrapper2 h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 720px) {
  .Wrapper {
    padding: 5% 2% !important;
  }
  .Wrapper h2 {
    font-size: 30px;
    width: 100%;
  }
  .Wrapper1 {
    padding: 5% 2% !important;
  }
  .Wrapper1 h2 {
    font-size: 30px;
    width: 100%;
  }
  .Wrapper2 {
    padding: 5% 2% !important;
  }
  .Wrapper2 h2 {
    font-size: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper h2 {
    font-size: 28px;
    width: 99%;
    height: 185px;
  }
  .Wrapper p {
    font-size: 16px;
    margin-top: 7%;
  }
  .Wrapper1 h2 {
    font-size: 28px;
    width: 99%;
    height: 155px;
  }
  .Wrapper1 p {
    font-size: 16px;
    margin-top: 7%;
  }
  .Wrapper2 h2 {
    font-size: 28px;
    width: 99%;
    height: 125px;
  }
  .Wrapper2 p {
    font-size: 16px;
    margin-top: 7%;
  }
}

@media screen and (max-width: 395px) {
  .Wrapper h2 {
    height: 190px !important;
  }
  .Wrapper1 h2 {
    height: 190px !important;
  }
  .Wrapper2 h2 {
    height: 190px !important;
  }
}
