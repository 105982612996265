.WrapperBack {
  background-image: url(../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0;
}
.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.moreImgs {
  width: 35%;
}
.megaImg {
  width: 100%;
  display: none;
}
.megaImg:nth-child(1) {
  display: block !important;
}
.moreImgs .miniImgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100% !important;
  margin-top: 5%;
}
.start button a {
  color: black;
  text-decoration: none;
}
.moreImgs .miniImgs img {
  width: 100% !important;
  height: 100px;
  border-radius: 10px;
}
.infors {
  width: 54%;
  background-color: white;
  padding: 4%;
  border-radius: 10px;
}
.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #17365e;
  padding-bottom: 3%;
}
.header h2 {
  color: #17365e;
  padding-bottom: 3%;
}
.buttons {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 3% 0 0 0 !important;
}
.buttons button {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: black;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 10px;
  transition: 0.65s;
  cursor: pointer;
}

.decription {
  margin-top: 3%;
}
.decription p {
  font-size: 16px;
  line-height: 160%;
}
.start p {
  color: #206623;
  margin: 0;
}
.start h3 {
  color: #1e1e1e;
  margin: 4% 0 6%;
  font-weight: 500;
}
.start button {
  padding: 10px 20px;
  background-color: #fcd318;
  color: #1e1e1e;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #fcd318;
}
.table {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card {
  margin-top: 30px;
  background-color: white;
  border-radius: 10px !important    ;
  padding-bottom: 15px;
  overflow: hidden;
  padding-bottom: 15px;
}
.texts {
  padding: 0px 15px;
}
.texts h3 {
  color: #17365e;
}

.card button {
  border: 1px solid #17365e;
  background-color: transparent;
  color: #17365e;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}
.card button p {
  margin: 2% 0 0 0;
  font-size: 20px;
  padding: 0;
}
.Cards {
  margin-top: 5%;
}
.Cards h2 {
  font-size: 40px;
}

.back {
  background: #f0f0f0;
}

@media screen and (max-width: 925px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .moreImgs {
    width: 100%;
  }
  .megaImg {
    width: 100%;
    height: 400px;
  }
  .infors {
    width: 85% !important;
  }
  .moreImgs div img {
    width: 300px !important;
    height: 200px;
  }
}
@media screen and (max-width: 580px) {
  .Cards h2 {
    font-size: 24px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 405px) {
  .Cards h2 img {
    width: 80px;
  }
}
@media screen and (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
  }
  .start {
    margin-top: 3%;
  }
  .moreImgs {
    text-align: center;
  }
  .megaImg {
    width: 99%;
    height: 100%;
    margin: 0 auto;
  }
  .moreImgs div img {
    width: 45% !important;
    height: 100%;
  }
  .buttons button {
    font-size: 14px;
  }
  .start button {
    font-size: 14px !important;
  }
  .start {
    margin: 10% 0 5%;
  }
}
