.WrapperBack {
  padding: 5% 0;
  background-image: url(../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.Wrapper h2 {
  font-size: 40px;
}
.Wrapper .cards {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 3%;
}
.Wrapper .cards h3 {
  font-size: 30px;
  color: #17365e;
}
.cards p {
  font-size: 16px;
  line-height: 140%;
}
.texts {
  width: 49%;
}
.cards img {
  width: 48%;
  border-radius: 10px;
}
@media screen and (max-width: 1025px) {
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cards:nth-child(2n) {
    display: flex;
    flex-direction: column-reverse;
  }
  .Wrapper {
    padding: 0px 10px;
  }
  .texts {
    width: 100%;
  }
  .cards img {
    margin: 0 auto;
    width: 80%;
  }
}
@media screen and (max-width: 485px) {
  .cards h3 {
    font-size: 24px !important;
  }
  .cards img {
    width: 100%;
  }
  .Wrapper h2 {
    font-size: 24px;
  }
}
