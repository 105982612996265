.WrapperBack {
  background-color: #f6f6f7;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.2%;
}
.request form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.request {
  background: url(../../../assets/request/back.png), #1e252d;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  padding: 2%;
}
.cart {
  width: 51%;
}
.cart iframe {
  width: 100%;
  height: 100%;
}
.request h2 {
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.subtext {
  color: white;
  margin: 1% 0 3%;
  font-weight: 600;
  letter-spacing: 0.01em;
  font-style: normal;
}

.request form input {
  background-color: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid white;
  margin-top: 6%;
  font-size: 14px;
  padding-bottom: 1%;
  width: 100%;
  outline: none;
  transition: 0.2s;
}
.request input:focus {
  border-bottom: 1px solid #ffe600;
  color: #ffe600;
}
.request input:focus::placeholder {
  color: #ffe600;
}
.request input::placeholder {
  color: white;
  font-style: normal;
  font-weight: 500;
  transition: 0.2s;
  font-size: 14px;
}
.request button {
  margin-top: 8%;
  color: #ffe600;
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.request button span {
  padding-right: 10%;
}

.request button p {
  margin: 0;
  font-size: 25px;
}
.dec {
  margin-top: 12% !important;
}
.info {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 8%;
  width: 100%;
}
.tel {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.tel p {
  margin: 0;
}
.location {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.location p {
  margin: 0;
}
.tel p:nth-child(2) {
  margin-top: 3%;
}
.icon {
  font-size: 30px;
  padding-right: 1%;
  background-color: #ffe600;
  padding: 5px 5px 0;
  border-radius: 5px;
}
.tel div {
  margin-left: 5%;
}
.tel div p {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  color: white;
  letter-spacing: 0.01em;
}

.location {
  /* margin-left: 5%; */
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.location div p {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  color: white;
  letter-spacing: 0.01em;
}
.mail {
  display: flex;
  align-items: flex-start;
  color: white;
  margin-top: 4%;
}
.mail p {
  margin: 0;
  color: white;
  font-style: normal;
  font-weight: bold;
}
.tel p {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}
.tel p a {
  color: white;
  text-decoration: none;
}
.mail div {
  margin-left: 2.5%;
}

@media screen and (max-width: 1075px) {
  .request h2 {
    font-size: 26px;
  }
  .location {
    width: 100%;
  }
  .info {
    width: 100%;
  }
  .tel {
    width: 100%;
  }
}

@media screen and (max-width: 860px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cart {
    width: 100%;
  }
  .cart iframe {
    width: 99% !important;
    height: 500px;
    margin: 0 auto !important;
    padding: 0 !important;
  }
  .cart {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .request {
    width: 90%;
    padding: 5%;
  }
}

@media screen and (max-width: 435px) {
  .info {
    display: flex;
    flex-direction: column;
  }
  .location {
    margin-top: 4%;
  }
  .cart iframe {
    height: 300px;
    width: 95% !important;
  }
}
