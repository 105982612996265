.WrapperBack {
  background-image: url(../../../assets/how/back.png),
    linear-gradient(100.38deg, #3f689c 2.83%, #17365e 75.2%);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0;
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.WrapperBack h2 {
  font-size: 40px;
  color: white;
  margin-bottom: 5%;
}

.Wrapper .texts {
  width: 49%;
}

.texts ul {
  padding: 0;
  margin: 0;
}
.texts ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.texts ul li img {
  margin: 0 3%;
}

.texts ul li p {
  color: #cccccc;
}
.texts ul li div {
  width: 40%;
}
.one {
  color: white !important;
  border: 3px solid #f5921d;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.two {
  color: white !important;
  border: 3px solid #1fb69a;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.three {
  color: white !important;
  border: 3px solid #f73555;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.four {
  color: white !important;
  border: 3px solid #0886cc;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}

.texts ul li div h3 {
  font-weight: 500;
  letter-spacing: 0.01em;
}
.texts ul li div p {
  line-height: 140%;
}

@media screen and (max-width: 1055px) {
  .Wrapper {
    padding: 0 10px;
  }
  .WrapperBack h2 {
    padding: 0 10px;
  }
  .img img {
    width: 400px;
    height: 400px;
  }
  .texts {
    width: 80% !important;
  }
}

@media screen and (max-width: 850px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .texts {
    width: 100% !important;
  }
  .texts ul li div {
    width: 60%;
  }
}
@media screen and (max-width: 410px) {
  .img img {
    width: 90% !important;
    height: 100% !important;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .one {
    width: 30px !important;
    height: 30px !important;
    font-size: 25px;
    padding: 2.8% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .two {
    width: 30px !important;
    height: 30px !important;
    font-size: 25px;
    padding: 2.8% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .three {
    width: 30px !important;
    height: 30px !important;
    font-size: 25px;
    padding: 2.8% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .four {
    width: 30px !important;
    height: 30px !important;
    font-size: 25px;
    padding: 2.8% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .texts ul li div {
    width: 100%;
  }
  .WrapperBack h2 {
    font-size: 24px !important;
  }
  .WrapperBack img {
    width: 80px;
  }
}
