.WrapperBack {
  padding: 5% 0;
  background-image: linear-gradient(100.38deg, #3f679c4f 2.83%, #17365e64 75.2%),
    url(../../../assets/partner/partner.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.Wrapper {
  text-align: center;
}
.Wrapper ul {
  display: flex;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
.Wrapper ul li {
  width: 30%;
}
.Wrapper ul li h3 {
  color: white;
  border: 13px solid #ffffff5e;
  width: 105px;
  margin: 0 auto;
  height: 105px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
}
.Wrapper ul li p {
  color: white;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 30px;
}
.Wrapper button {
  background-color: #fcd318;
  color: black;
  border: 1px solid #fcd318;
  font-size: 16px;
  margin-top: 5%;
  border-radius: 10px;
  padding: 10px 20px;
  letter-spacing: 0.01em;
}
.Wrapper button a {
  color: black;
  text-decoration: none;
}
@media screen and (max-width: 1115px) {
  .Wrapper ul {
    width: 100%;
  }
}
@media screen and (max-width: 875px) {
  .Wrapper ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Wrapper ul li {
    margin-bottom: 5%;
  }
  .Wrapper ul li p {
    margin-top: 3%;
  }
  .Wrapper button {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .Wrapper ul li p {
    font-size: 20px;
  }
}
