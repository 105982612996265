.ant-tabs-content-holder {
  display: none !important;
}
.ant-tabs-tab-btn {
  color: rgb(0, 0, 0) !important;
  padding: 10px 25px !important;
  border-radius: 20px !important;
  background-color: white !important;
  border: 1px solid white !important;
  letter-spacing: 0.01em !important;
  font-weight: 600 !important;
}
.ant-tabs-ink-bar {
  display: none !important;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  background-color: #fcd318 !important;
  color: black !important;
}
