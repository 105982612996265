.WrapperBack {
  padding: 5% 0;
  background-image: url(../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: 3%;
}
.cards img {
  width: 48%;
  height: 100%;
  border-radius: 10px;
}
.texts {
  width: 48%;
}
.cards p {
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.01em;
  line-height: 130%;
}
.one {
  color: #f5921d !important;
  border: 3px solid #f5921d;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.two {
  color: #1fb69a !important;
  border: 3px solid #1fb69a;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.three {
  color: #f73555 !important;
  border: 3px solid #f73555;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.four {
  color: #0886cc !important;
  border: 3px solid #0886cc;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.Wrapper h2 {
  font-size: 40px;
  padding-bottom: 2%;
}

@media screen and (max-width: 1000px) {
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .cards:nth-child(2n) {
    display: flex;
    flex-direction: column-reverse;
  }
  .texts {
    width: 95%;
    padding: 0 10px;
  }
  .cards img {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .cards img {
    width: 95%;
  }
}

@media screen and (max-width: 522px) {
  .Wrapper h2 {
    font-size: 24px;
    padding: 0px 10px 20px;
  }
  .Wrapper h2 img {
    width: 80px;
  }
  .one,
  .two,
  .three,
  .four {
    width: 50px;
    height: 50px;
  }
}
