.WrapperBack {
  padding: 5% 0 3%;
  background-image: url(../../../assets/info/back.png);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position-y: bottom;
  background-position-x: 118%;
}
.WrapperDivBack {
  background-image: url(../../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.img {
  width: 45%;
}
.texts {
  width: 49%;
}

.texts h2 {
  font-size: 40px;
}
.texts p {
  line-height: 160%;
  font-size: 16px;
  margin-top: 3%;
}

.img img {
  width: 100%;
}

.Galery {
  padding: 5% 0;
}
.Galery h2 {
  font-size: 40px;
}
.clients {
  padding: 5% 0;
}
.clients h2 {
  font-size: 40px;
}
.clients ul {
  display: flex;
  margin-top: 3%;
  align-items: center;
  justify-content: space-between;
  overflow: scroll;
}
.clients ul::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 990px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
  }
  .texts {
    width: 100%;
  }
  .img {
    width: 100%;
  }
}

@media screen and (max-width: 585px) {
  .Wrapper h2 {
    font-size: 24px;
  }
  .Galery h2 {
    font-size: 24px;
    padding: 0px 10px;
  }
  .clients h2 {
    font-size: 24px;
    padding: 0 10px;
  }
  .Wrapper h2 img {
    width: 100px !important;
  }
  .Galery h2 img {
    width: 100px !important;
  }
  .clients h2 img {
    width: 100px !important;
  }
}

@media screen and (max-width: 340px) {
  .Wrapper h2 img {
    width: 74px;
  }
  .Galery h2 img {
    width: 74px;
  }
  .clients h2 img {
    width: 74px  !important;
  }
}
