.WrapperBack {
  background-image: url(../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0;
}
.card {
  /* border: 1px solid black; */
  margin-top: 30px;
  background-color: white;
  border-radius: 10px !important    ;
  padding-bottom: 15px;
  overflow: hidden;
  padding-bottom: 15px;
}
.card img {
  width: 100% !important;
}

.texts {
  padding: 0px 15px;
}
.texts h3 {
  color: #17365e;
}
.Wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3%;
}
.Wrapper h2 img {
  height: 10px;
}
.card button {
  border: 1px solid #17365e;
  background-color: transparent;
  color: #17365e;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}
.card a {
  text-decoration: none;
}
.card button span {
  margin-right: 5% !important;
}
.card img {
  display: none;
}
.card img:nth-child(1) {
  display: block !important;
}
@media screen and (max-width: 480px) {
  .Wrapper h2 {
    font-size: 24px;
    padding: 0 10px;
  }
  .Wrapper img {
    width: 100px;
  }
}
