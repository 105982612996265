.WrapperBack {
  padding: 5% 0;
  background-image: url(../../../assets/info/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.Wrapper {
  text-align: center;
}
.Wrapper h2 {
  font-size: 40px;
  margin-bottom: 3%;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cards .card {
  width: 32%;
  background: linear-gradient(100.38deg, #3f689c 2.83%, #17365e 75.2%);
  padding: 0% 2% 2%;
  height: 220px;
  border-radius: 5px;
}
.card .cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardHeader h3 {
  color: white;
  font-size: 35px;
  border: 13px solid rgba(255, 255, 255, 0.432);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card p {
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}
.arrow {
  margin: 0 1.5%;
}
.warring {
  font-size: 20px;
  margin-top: 3%;
}
.subtext {
  font-size: 16px;
  line-height: 140%;
}
@media screen and (max-width: 1055px) {
  .arrow {
    display: none;
  }
  .card {
    width: 28% !important;
  }
}
@media screen and (max-width: 860px) {
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card {
    width: 70% !important;
    padding: 5%;
    margin-top: 3%;
    height: 260px !important ;
  }
  .subtext {
    padding: 0px 10px;
  }
  .warring {
    margin-top: 8%;
  }
}
@media screen and (max-width: 440px) {
  .card {
    width: 92% !important;
    height: 260px !important ;
  }
  .Wrapper h2 {
    font-size: 20px;
  }
}
